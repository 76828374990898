import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import { Container } from "react-bootstrap";

const PageLayout = () => {
    return (
        <>
            <NavBar />
            <Outlet />
        </>
    );
};

export default PageLayout;