import { Button, Form, Modal } from "react-bootstrap"
import { GoogleFormProvider, useGoogleForm, useLongAnswerInput, useShortAnswerInput } from "react-google-forms-hooks"

import form from './contactForm.json'
import { useState } from "react"

const ShortAnswerInput = ({ id }) => {
    const { register } = useShortAnswerInput(id)
  
    return (
        <Form.Control type="text" {...register()} />
    )
}

const LongAnswerInput = ({ id }) => {
    const { register } = useLongAnswerInput(id)
  
    return (
        <Form.Control as="textarea" rows={3} {...register()} />
    )
}

const Questions = () => {
    return (
        <>
            {form.fields.map((field) => {
                const { id } = field;
        
                let questionInput = null;
                switch (field.type) {
                    case 'LONG_ANSWER':
                        questionInput = <LongAnswerInput id={id} />;
                        break;
                    default:
                        questionInput = <ShortAnswerInput id={id} />;
                        break;
                }
        
                if (!questionInput) {
                    return null;
                }
        
                return (
                    <Form.Group key={id}>
                        <Form.Label>{field.label}</Form.Label>
                        {questionInput}
                        <Form.Text className="text-muted">{field.description}</Form.Text>
                    </Form.Group>
                )
            })}
        </>
    )
}

const ContactForm = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const methods = useGoogleForm({ form });
    const onSubmit = async (data) => {
        await methods.submitToGoogleForms(data);
        handleShow();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Contato solicitado!</Modal.Title>
                    <Modal.Body>Em breve entraremos em contato.</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleClose}>Ok</Button>
                    </Modal.Footer>
                </Modal.Header>
            </Modal>
            <GoogleFormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)} className="download-button">
                    <Questions />
                    <Button type='submit' className="download-button">Solicitar Contato</Button>
                </Form>
            </GoogleFormProvider>
        </>
    );
};

export default ContactForm;