import { Container, Image, Nav, Navbar } from "react-bootstrap";

const NavBar = ({ currentPage }) => {
    return (
        <>
            <Navbar bg="green" variant="pills" defaultActiveKey="/" className="nav-bar" expand>
                <Container>
                    <Navbar.Brand href="/" className="nav-brand">
                        <Image src="logo512.png" className="logo-brand"/>
                        EuroPatinhas
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link href="/">Guia de viagem</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="navbar-footer"></div>
        </>
    );
};

export default NavBar;